@import '../../../styles/global/mixins';

.main {
  display: flex;
  justify-content: space-between;

  @include mobile-only {
    flex-direction: column;

    > * {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.element,
.title {
  font-weight: 300;
}

.element {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  opacity: 0.6;
  font-size: 14px;
  text-transform: uppercase;
}

.large .content {
  font-size: 24px;
  font-weight: 500;
}
