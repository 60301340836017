@import '../../styles/global/variables';

.main {
  padding: 0em 1.25em;
  font-size: 1.5rem;
}

.removeChipButton:hover {
  background-color: $fi-yellow-color;
}
