@import '../../../styles/global/variables';

.main {
  display: flex;
}

.picture {
  margin-right: 20px;

  img {
    display: block;
    border-radius: 50%;
    object-fit: cover;
    width: 90px;
    height: 90px;
  }
}

.editablePicture img {
  cursor: pointer;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  font-size: $av-font-size-lg;
  font-weight: 500;
}

.status {
  color: $status-active-color;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: $status-active-color;
    margin: 0 10px 4px 0;
  }

  &.pending {
    color: $status-review-color;

    &::before {
      background-color: $status-review-color;
    }
  }

  &.inactive {
    color: $status-shipped-color;

    &::before {
      background-color: $status-shipped-color;
    }
  }
}

.contactInfoSourceContainer {
  text-align: right;
  flex-grow: 1;
}
