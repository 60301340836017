.action {
  display: flex;

  > * {
    flex-grow: 1;
  }
}

.divided {
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
}

.obfuscatedEmail {
  font-size: 18px;
}
