.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10em 0;

  img {
    width: 100%;
    max-width: 5em;
    max-height: inherit;
  }
}
