@import '../../../styles/global/mixins';

.main {
  background-image: url('../../../assets/images/footer/scribble-footer.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
  margin-top: 50px;

  @include mobile-only {
    background-size: 60%;
  }
}

.contents {
  display: flex;
  margin: 10px 30px;

  > * {
    flex-grow: 1;
    flex-basis: 0;
  }

  @include mobile-and-tablet-only {
    flex-direction: column;
    > * {
      flex-grow: unset;
      flex-basis: unset;
    }
  }
}

.left {
  flex-grow: 2;
  display: flex;
  align-items: center;

  > * {
    margin: 0 100px;
    display: flex;
    flex-direction: column;

    @include mobile-and-tablet-only {
      margin: 0;
      flex-grow: 1;
    }
  }
}

.right {
  display: flex;
  justify-content: flex-end;

  @include mobile-and-tablet-only {
    justify-content: center;
    text-align: center;
  }

  > * {
    width: 50%;

    @include mobile-only {
      width: 90%;
    }

    @include desktop-only {
      margin-right: 60px;
    }
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 50px;
  list-style: none;
  order: 3;

  @include mobile-only {
    flex-direction: column;
    margin-bottom: 50px;
    order: 1;
  }

  a {
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    color: rgba(0, 0, 0, 0.9);
  }
}

.hours {
  p {
    opacity: 0.4;
    margin: 0.75rem 0 1.5rem;
    line-height: 1.2;
    font-size: 21px;
  }

  h5 {
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
    font-size: 21px;
  }

  @include mobile-and-tablet-only {
  }
}

.appstores {
  display: flex;
  flex-wrap: nowrap;

  a {
    flex-shrink: 1;
    display: block;
  }

  img {
    height: auto;
    width: 100%;
  }

  a:first-of-type {
    margin-right: 1rem;
  }
}

.social {
  display: flex;
  flex-direction: row;
  margin: 2rem 0;

  a {
    opacity: 0.4;

    padding: 0.25rem;

    &:hover {
      opacity: 0.8;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @include mobile-and-tablet-only {
    justify-content: center;
  }
}

.contact {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
}

.button {
  color: black;
  border: 1px solid black;
  padding: 10px 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 21px;
}

.copyright {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.2;
}

.mobileCopyright {
  @include desktop-only {
    display: none;
  }
}

.desktopCopyright {
  @include mobile-and-tablet-only {
    display: none;
  }
}
