@import '../../styles/global/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;

  padding: 0;
  margin: 0;
  background-color: #f3f3f2;
  max-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 90px);
}

@media (max-width: 600px) {
  .container {
    background-color: white;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: 656px;
  padding: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 32px;

  border-radius: 24px;
  background-color: #ffffff;
}

.form:has(:global(.success-container)) {
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 0px;
  max-width: unset;
  height: calc(100vh - 88px);
}
