@import './styles/global/fonts';
@import './styles/global/variables';
@import './styles/global/typography';
@import './styles/global/mixins';
@import './styles/slick/slick.min';

a,
.link {
  transition: color 0.2s linear;
  text-decoration: none;
  color: #027eff;
  cursor: pointer;

  &.link__underlined {
    text-decoration: underline;
  }

  &.link__black {
    color: black;
  }
}

body {
  font-family: $font-family-sans-serif;
  font-size: 17px;
  margin: 0;
  padding: 0;
  font-weight: 300;

  @include mobile-and-tablet-only {
    font-size: 18px;
  }
}

.single-column {
  max-width: 600px;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: 600px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  &.single-column--centered {
    align-items: center;
  }

  &.single-column--narrow {
    max-width: 400px;
  }
}

.disclaimer {
  opacity: 0.75;
  font-size: 16px;
  padding: 5px;

  @include mobile-and-tablet-only {
    text-align: center;
  }
}

.uppercase {
  text-transform: uppercase;

  span.no-uppercase {
    text-transform: none;
  }
}

/*
 * Input elements
 */

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

button:disabled {
  color: black;
}

input {
  background: transparent;
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='tel'],
input[type='email'],
textarea {
  border: none;
  font-size: 24px;
  font-family: $font-family-sans-serif;
  font-weight: 300;

  &::placeholder {
    opacity: 0.65;
  }
}

textarea {
  resize: none;
}

.textarea-container {
  padding: 1rem;
  border-bottom: 1px solid #d8d8d8;
  display: flex;

  textarea {
    flex-grow: 1;
  }
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='tel'],
input[type='email'] {
  padding: 1rem;
  border-bottom: 1px solid #d8d8d8;
}

/*
 * Popups
 */

.popup-content {
  @include mobile-and-tablet-only {
    // Library sets styles using inline styles, so have to !important here :(
    width: 90% !important;
  }

  max-width: 600px;
  background-color: #ffffff;
  border-radius: 16px;
}

/*
 * Buttons
 */

button,
input[type='submit'] {
  font-family: $font-family-sans-serif;
  border: none;
  cursor: pointer;
}

@mixin btn() {
  cursor: pointer;
  text-align: center;
}

.btn-yellow {
  @include btn;

  background: #ffff01;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0.75rem 2rem 0.5rem 2rem;
  font-weight: 600;
  font-size: 18px;
  color: black;

  transition: transform 0.15s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

.btn-outline {
  @include btn;

  border: 1px solid #000000;
  border-radius: 4px;
  padding: 0.75rem 2rem 0.5rem 2rem;
  font-weight: 600;
  font-size: 18px;
  color: black;
  transition: transform 0.15s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

.btn-black {
  @include btn;

  background: #000000;
  border-radius: 1.6px;
  text-transform: uppercase;
  padding: 0.75rem 2.5rem 0.5rem 2.5rem;
  text-decoration: none;
  color: white;
  line-height: 24px;
  font-size: 1rem;
  font-weight: 400;
  transition: transform 0.15s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

.btn-black-outline {
  @include btn;

  color: black;
  text-transform: uppercase;
  padding: 0.75rem 2.5rem 0.5rem 2.5rem;
  text-decoration: none;
  line-height: 24px;
  font-weight: 400;
  border: 1px solid black;
  transition: transform 0.15s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.btn-yellow-flat {
  @include btn;

  display: inline-block;
  background: #ffff01;
  padding: 0.75rem 2.5rem 0.5rem 2.5rem;
  font-weight: 600;
  font-size: 18px;
  color: black;
  text-transform: uppercase;
  transition: transform 0.15s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}
.btn-black-flat {
  @include btn;

  display: inline-block;
  background: #000;
  padding: 0.75rem 2.5rem 0.5rem 2.5rem;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  transition: transform 0.15s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

/*
 * Forms
 */

.form {
  display: flex;
  flex-direction: column;

  .form-group {
    display: flex;
    margin-top: 15px;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    &.form-group--action {
      margin-top: 30px;
    }

    &.form-group--action__wide {
      button {
        align-self: stretch;
      }
    }

    &.form-group--multistep-action {
      justify-content: flex-end;
      align-items: center;
    }
  }

  &:not(.form__app) {
    .form-group {
      @include mobile-only {
        flex-direction: column;
        align-items: stretch;
      }
    }

    .form-field:not(:first-child) {
      @include mobile-only {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  &.form__app {
    input[type='text'],
    input[type='number'],
    input[type='tel'],
    input[type='email'] {
      width: 100%;
      box-sizing: border-box;
    }

    .form-field {
      flex-basis: 0;
    }
  }

  .form-field__error {
    margin: 10px 10px 0 10px;
    font-weight: 300;
    color: #ff004f;
  }

  .form-field {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;

    &:not(:first-child) {
      margin-left: 20px;
    }

    button {
      align-self: flex-start;

      @include mobile-only {
        align-self: stretch;
      }
    }

    &.form-field--labelled {
      > div:first-child {
        display: flex;
        justify-content: space-between;
      }
    }

    &.form-field--inline-action {
      flex-grow: 0;
      white-space: nowrap;
    }
  }

  input[type='date'] {
    font-family: $font-family-sans-serif;

    /** Some workarounds to get the date picker to show a placeholder as a label */
    &:before {
      content: '';
    }

    &::-webkit-datetime-edit-text,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-year-field {
      color: #000000;
    }

    &.date-input--empty::-webkit-datetime-edit-text,
    &.date-input--empty::-webkit-datetime-edit-month-field,
    &.date-input--empty::-webkit-datetime-edit-day-field,
    &.date-input--empty::-webkit-datetime-edit-year-field {
      color: #999;
    }

    &.date-input--empty:before {
      content: attr(placeholder) !important;
      color: #aaa;
      margin-right: 0.5em;
      font-size: 1.25rem;
    }
  }

  .form-disclaimer {
    color: black;
    font-family: $font-default;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    padding: 0px 0px 10px 0px;
  }
}

/*
 * React Select
 */
.react-select-container {
  $blue: #3497ff;

  margin: 0;
  position: relative;
  top: -5px;

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px $blue;
    border-color: $blue;
  }

  .react-select__option--is-selected {
    background-color: $blue;
  }

  .react-select__input {
    height: 30px;

    input {
      margin: 1px 0 1px -1px !important;
    }
  }

  .react-select__indicator-separator {
    background-color: rgba(0, 0, 0, 0);
  }

  .react-select__value-container *:last-child {
    padding: 0;
  }
}

.register-chip-next .form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 2em;
}

.register-chip-next .form-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 1em;
}

.register-chip-next .form-group {
  margin: 0;
}

.register-chip-next .form-input {
  display: flex;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  height: 56px;
  padding: 16px 24px;
  gap: 8px;

  border-radius: 100px;
  border: 1px solid #bebebb;
  background-color: #ffffff;
}

.register-chip-next .form-input {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.register-chip-next .form-input::placeholder {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #9e9e9c;
}

.register-chip-next .button-main {
  display: flex;
  height: 55px;
  padding: 18px 33px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;

  font-family: $font-default;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;
  background-color: #ffffff;
  border: 1px solid #000;

  &--primary {
    background-color: #000000;
    color: #ffffff;
    border: 1px solid transparent;
  }
}

.register-chip-next .form-field input {
  flex-grow: 1;
  display: flex;
  height: 24px;
  min-height: 0;
  padding: 16px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  box-sizing: content-box;

  border-radius: 100px;
  border: 1px solid #bebebb;
  background-color: #ffffff;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: black;
}

.register-chip-next .form-field input::placeholder {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #9e9e9c;
  opacity: 1;
}

.register-chip-next .form-field input[type='date'] {
  font-family: $font-default;

  /** To get input looking normal on mobile (https://stackoverflow.com/a/52928744) */
  -webkit-appearance: none;
  appearance: none;

  /** Some workarounds to get the date picker to show a placeholder as a label */
  &:before {
    content: '';
  }

  &::-webkit-datetime-edit-text,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field,
  &::-webkit-datetime-edit-year-field {
    color: #000000;
  }

  &.date-input--empty::-webkit-datetime-edit-text,
  &.date-input--empty::-webkit-datetime-edit-month-field,
  &.date-input--empty::-webkit-datetime-edit-day-field,
  &.date-input--empty::-webkit-datetime-edit-year-field {
    color: #999;
  }

  &.date-input--empty:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
    font-size: 1rem;
  }
}

.register-chip-next .form-group {
  font-family: $font-default;
  font-size: 16px;
}

.register-chip-next .form-group--action {
  flex-direction: column;
  align-items: initial;
  margin-top: 16px;
}

.register-chip-next .form-group--action .form-field {
  flex-direction: row;
  gap: 16px;
}

@media (max-width: 600px) {
  .register-chip-next .form-group--action .form-field {
    flex-direction: column;
  }
}

.register-chip-next .form-field__error {
  font-family: $font-default;
  font-size: 16px;
}

.register-chip-next .form-group--action .form-field button:nth-child(2) {
  color: black;
  background-color: white;
  border: 1px solid black;
}

.register-chip-next .form .form-group.form-group--multistep-action,
.register-chip-next .form .form-group.form-group--action {
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;

  button {
    width: auto;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;

    button {
      width: 100%;
    }
  }
}
