.main {
  text-transform: uppercase;
  display: flex;
  font-weight: 400;
  padding: 10px;
  justify-content: center;
  margin: 10px;
  cursor: pointer;
}

.secondary {
  border: 1px solid black;
}

.primary {
  color: white;
  background-color: black;
}

.disabled {
  opacity: 0.6;
  cursor: default;
}

.soft {
  text-transform: none;
  border-radius: 4px;
}
