@import '../../styles/global/variables';

.main {
  display: flex;
  margin: 20px 10px;

  > * {
    flex-grow: 1;
    flex-basis: 0;
  }

  > .title {
    flex-grow: 2;
  }
}

.title {
  text-align: center;
  font-size: $av-font-size-lg;
  font-weight: 600;
  padding: 10px;
}

.edit {
  display: flex;
  justify-content: flex-end;
  font-size: $av-font-size-md;
  opacity: 0.55;
  align-items: center;

  > * {
    cursor: pointer;
    padding: 10px;
  }
}

.back {
  display: flex;
  align-items: center;

  img {
    display: block;
    padding: 10px;
    cursor: pointer;
  }
}
