@import '../../styles/global/variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;

  label {
    font-family: $font-default;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: black;
    width: 64px;
  }

  .inputContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #bebebb;
    height: 56px;
    padding: 0;
    overflow: hidden;
  }

  .radioOption {
    font-family: $font-default;
    font-size: 16px;
    font-weight: 600;
    flex-grow: 1;
    border-left: 1px solid #bebebb;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.selected {
      background-color: #f3f3f2;

      .radioText {
        &::before {
          content: url(../../assets/images/check.svg);
          margin-right: 4px;
        }
      }
    }
  }

  .radioOption:first-child {
    border-left: none;
  }
}
