.main {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:last-child {
    border-bottom: none;
  }

  a {
    display: block;
  }
}

.title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
}
