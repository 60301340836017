@import '../../styles/global/variables';

.main {
  overscroll-behavior-y: none;

  h3 {
    font-size: $av-font-size-lg;
  }

  font-size: $av-font-size-md;

  :global(.form) {
    :global(.form-field__error) {
      font-size: $av-font-size-md;
    }
  }
}

.desktop {
  max-width: 860px;
  margin: 20px auto;
}
