@font-face {
  font-family: 'Pakt';
  src: url('../../assets/fonts/Pakt Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Regular Typeface.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Italic Typeface.otf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Demi Typeface.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Demi Italic Typeface.otf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Bold Typeface.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Bold Italic Typeface.otf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Extra Bold Typeface.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Extra Bold Italic Typeface.otf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Light Typeface.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Light Italic Typeface.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Extra Light Typeface.otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: DIN2014;
  src: url('../../assets/fonts/DIN 2014 Extra Light Italic Typeface.otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: DIN2014-Narrow;
  src: url('../../assets/fonts/DIN 2014 Narrow Typeface.otf');
  font-weight: normal;
}

@font-face {
  font-family: DIN2014-Narrow;
  src: url('../../assets/fonts/DIN 2014 Narrow Extra Light Typeface.otf');
  font-weight: 100;
}

@font-face {
  font-family: DIN2014-Narrow;
  src: url('../../assets/fonts/DIN 2014 Narrow Light Typeface.otf');
  font-weight: 300;
}

@font-face {
  font-family: DIN2014-Narrow;
  src: url('../../assets/fonts/DIN 2014 Narrow Demi Typeface.otf');
  font-weight: 600;
}

@font-face {
  font-family: DIN2014-Narrow;
  src: url('../../assets/fonts/DIN 2014 Narrow Bold Typeface.otf');
  font-weight: bold;
}

@font-face {
  font-family: DIN2014-Narrow;
  src: url('../../assets/fonts/DIN 2014 Narrow Extra Bold Typeface.otf');
  font-weight: 900;
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/messina-sans/MessinaSans-Regular.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/messina-sans/MessinaSans-RegularItalic.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: bold;
  font-style: normal;
  src: url('../../assets/fonts/messina-sans/MessinaSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: bold;
  font-style: italic;
  src: url('../../assets/fonts/messina-sans/MessinaSans-BoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 200;
  font-style: normal;
  src: url('../../assets/fonts/messina-sans/MessinaSans-Light.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/messina-sans/MessinaSans-LightItalic.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 500;
  font-style: normal;
  src: url('../../assets/fonts/messina-sans/MessinaSans-Regular.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/messina-sans/MessinaSans-RegularItalic.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 600;
  font-style: normal;
  src: url('../../assets/fonts/messina-sans/MessinaSans-SemiBold.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/messina-sans/MessinaSans-SemiBoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/messina-sans/MessinaSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/messina-sans/MessinaSans-BoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans/MessinaSans-BoldItalic.woff') format('woff');
}

/** Messina Sans Compact */

@font-face {
  font-family: 'MessinaSans-Compact';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedRegular.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedRegular.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans-Compact';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedRegularIt.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedRegularIt.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans-Compact';
  font-weight: bold;
  font-style: normal;
  src: url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedBold.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedBold.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans-Compact';
  font-weight: bold;
  font-style: italic;
  src: url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedBoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans-Compact';
  font-weight: 200;
  font-style: normal;
  src: url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedLight.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedLight.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSans-Compact';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedLightIt.woff2') format('woff2'),
    url('../../assets/fonts/messina-sans-compact/MessinaSans-CondensedLightIt.woff') format('woff');
}
