@import '../../styles/global/variables';

$compact-form-input-background: #f8f8f8;
$compact-form-input-border-radius: 0.2em;
$compact-form-input-placeholder-color: #737373;
$compact-form-input-font-size: 1.25rem;

@mixin compact-form-input() {
  background: $compact-form-input-background;
  color: #000000;
  border: none;
  border-radius: $compact-form-input-border-radius;
  font-size: $compact-form-input-font-size;
}

@mixin placeholder() {
  color: $compact-form-input-placeholder-color;
  font-weight: 400;
  opacity: 0.7 !important;
}

.compact {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  input {
    @include compact-form-input();
    padding: 0.7em 0.8em;
  }

  :global input.locked {
    background-color: transparent;
    color: #000000;
    background-image: url(../../assets/images/lock.svg);
    background-position: center right;
    background-repeat: no-repeat;
  }

  input[type='date'] {
    min-height: 56px; // min-height on date fields needed for Safari
    box-sizing: border-box;
  }

  ::placeholder {
    @include placeholder();
  }

  :global {
    .form-group {
      align-items: flex-start;
      font-size: $compact-form-input-font-size;
    }

    .react-select {
      &-container {
        top: 0;
        line-height: 2.2em;
      }

      &__control {
        @include compact-form-input();
      }

      &__placeholder {
        @include placeholder();
      }

      &__value-container {
        padding: 0.19em 0.7em;
      }
    }
  }

  .checkboxCustom {
    font-size: $compact-form-input-font-size;
  }
}

.next {
  :global {
    .react-select {
      &-container {
        flex-grow: 1;
        font-size: 16px;
        font-family: $font-default;
        line-height: 1.5em;
        top: 0;
      }

      &__control {
        font-size: 16px;
        padding: 1em 1.5em;
        background-color: white;
        border-radius: 100px;
        border: 1px solid #bebebb;
        background-color: #ffffff;
        min-height: 0;
        height: 24px;
        box-sizing: content-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      &__placeholder {
        font-size: 16px;
        color: #9e9e9c;
      }

      &__value-container {
        flex-grow: 1;
        position: static;
        padding: 0;
        font-family: $font-default;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;

        & > div {
          margin: 0;
          padding: 0;
          box-sizing: content-box;
        }
      }

      &__input {
        height: auto;
        display: block !important;
      }

      &__indicator-separator {
        margin-top: 0;
        margin-bottom: 0;
      }

      &__indicator {
        padding: 0;
        margin: 0;
      }

      &__menu {
        font-family: $font-default;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
}
