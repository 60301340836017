@import '../../styles/global/variables';

.main {
  display: flex;
  padding: 30px 20px;
  border-bottom: 1px solid #979797;
  justify-content: space-between;
  cursor: pointer;
}

.chipDetails {
  display: flex;
  align-items: center;
}

.icon {
  margin: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  align-self: center;

  &.review {
    background-color: $status-review-color;
  }

  &.active {
    background-color: $status-active-color;
  }

  &.shipped {
    background-color: $status-shipped-color;
  }
}

.chipId {
  margin-right: 20px;
}

.shortId {
  font-size: $av-font-size-md;
}

.serialNumber {
  font-size: $av-font-size-xsm;
  opacity: 0.7;
}

.secondary {
  font-size: $av-font-size-sm;
  opacity: 0.7;
}
