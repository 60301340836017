.message {
  text-align: center;
  margin: 40px;
}

.action {
  padding: 10px;
  border-top: 1px solid #d8d8d8;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}
