/*
 * Custom Checkbox
 */

.checkboxCustom {
  padding-left: 0;
  display: flex;
  align-items: center;
  line-height: 1em;

  input[type='checkbox'] {
    display: none;
  }
}

.checkboxIcon {
  background: url('../assets/images/checkbox_unchecked.svg');
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  vertical-align: text-top;

  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
    background-size: 20px;
  }
}

.checkboxIconDisabled {
  background: url('../assets/images/checkbox_disabled.svg');

  @media (max-width: 600px) {
    background-size: 20px;
  }
}

.checkboxIconChecked {
  background: url('../assets/images/checkbox_checked.svg');

  @media (max-width: 600px) {
    background-size: 20px;
  }
}
