.main {
  padding: 20px 10px;
  display: flex;

  input {
    flex-grow: 1;
    min-width: 4em;
  }

  .backButton {
    display: block;
    padding: 10px;
    margin-right: 10px;
  }
}

.action {
  display: flex;
  width: 40px;
  padding: 10px;
  margin-left: 20px;
  flex-shrink: 0;

  img {
    display: block;
    width: 100%;
    cursor: pointer;
  }
}
