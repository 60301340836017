@import '../../../styles/global/variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 40px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 656px;
  padding: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 32px;

  border-radius: 24px;
  background-color: #ffffff;
}

.desktopSidebarContent {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 424px;
  padding: 40px;
  margin-top: 40px;
  gap: 32px;

  border-radius: 24px;
  background-color: #ffffff;
}

.desktopSidebarContent:nth-of-type(2) {
  margin-top: 16px;
}

.progressText {
  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.14px;
  color: #1360f4;
}

.progressFillBoxes {
  display: flex;
  height: 3px;
  gap: 6px;
  width: 100%;
  margin-top: 4px;
}

.progressFillBox1,
.progressFillBox2,
.progressFillBox3,
.progressFillBox4,
.progressFillBox5 {
  background-color: #1360f4;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.title {
  font-family: $font-title;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;

  line-height: 100%;
  letter-spacing: -1.12px;

  color: #000000;
}

.bulletPointContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 20px;
}

.bulletPoint {
  display: flex;
  align-items: flex-start;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: black;
}

.bulletPoint svg {
  width: 24px;
  height: 24px;
  overflow: initial;
  padding-top: 3px;
  padding-right: 12px;
}

.fiCollarImage {
  display: block;
  height: auto;
  max-width: 926px;
  margin-top: 32px;
}

.ctaContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.tryItButton {
  display: flex;
  height: 55px;
  padding: 18px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-top: 4px;
  max-width: 250px;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;
  text-align: center;
  color: white;
  background-color: black;
}

.skipThisDealButton {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;
  text-align: center;
  color: black;
  background-color: transparent;
}

.locationTrackingImage {
  display: block;
  width: 100%;
  height: auto;
}

.titleContainer .locationTrackingImage {
  display: none;
}

.titleContainer .gpsPulsingContainer {
  display: none;
}

.gpsPulsingContainer {
  display: flex;
  justify-content: center;
  position: relative;
}

.gpsPinContainer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20%;
  aspect-ratio: 1 / 1;

  top: calc(100% * 0.36);
  @media (max-width: 1000px) {
    top: calc(100% * 0.365);
  }
  @media (max-width: 800px) {
    top: calc(100% * 0.37);
  }
  @media (max-width: 600px) {
    top: calc(100% * 0.375);
  }
  @media (max-width: 500px) {
    top: calc(100% * 0.38);
  }
  @media (max-width: 400px) {
    top: calc(100% * 0.395);
  }
}

.gpsAvatarPinImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}

.gpsRings {
  position: absolute;
  top: calc(50% - 6px);
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #07a157;
  opacity: 0.16;
  z-index: 1;
}

.gpsRings::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(2.5);
  background-color: #07a157;
  animation: pulse 2s ease-out infinite;
  animation-delay: 100ms;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
  }
}

.testimonialCarousel {
  .testimonialCard {
    position: relative;
    width: 100%;
    height: 150px;
    overflow: hidden;
    white-space: nowrap;

    .testimonialStars {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }

    .testimonial {
      display: flex;
      flex-direction: column;
      gap: 24px;
      position: absolute;
      width: 100%;
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

      &.prev {
        opacity: 0;
        transform: translateX(-100%);
      }

      &.active {
        opacity: 1;
        transform: translateX(0);
      }

      &.next {
        opacity: 0;
        transform: translateX(100%);
      }

      &.hidden {
        display: none;
      }

      .testimonialText {
        font-family: $font-default;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.48px;
        text-align: center;
        color: black;
        text-wrap: wrap;
      }

      .testimonialByline {
        font-family: $font-default;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.14px;
        text-align: center;
        color: #737373;
        margin-top: 4px;
      }
    }
  }

  .dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .dot {
      width: 6px;
      height: 6px;
      background-color: gray;
      border-radius: 50%;
      margin: 0 5px;
      transition: width 0.3s ease, height 0.3s ease;

      &.active {
        border-radius: 100px;
        width: 16px;
        height: 6px;
        background-color: black;
      }
    }
  }
}

.mobileContainer {
  display: none;
}

.dealContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 1000px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    overflow-x: hidden;
  }

  .mainContent {
    max-width: none;
    width: 100%;
    border-radius: 0px;
    padding: 24px 16px;
    gap: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .title {
    font-size: 40px;
  }

  .bulletPointContainer {
    margin-top: 24px;
  }

  .mainContent .dealContainer,
  .desktopSidebarContent {
    display: none;
  }

  .mobileContainer {
    display: initial;
    width: 100%;
  }

  .mobileContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-top: 6px solid #f3f3f2;
    border-bottom: 6px solid #f3f3f2;
  }

  .dealContainer {
    padding: 24px 16px;
    background-color: white;
  }

  .ctaContainer {
    padding: 8px 0px;
    flex-direction: column;
    align-items: center;
  }

  .tryItButton {
    max-width: initial;
    align-self: stretch;
  }

  .titleContainer .locationTrackingImage {
    display: block;
    align-self: center;
    margin-top: 24px;
    width: 80%;
  }

  .titleContainer .gpsPulsingContainer {
    display: flex;
    align-self: center;
    justify-content: center;
    margin-top: 24px;
    width: 80%;
  }

  .fiCollarImage {
    margin-top: 12px;
  }

  .testimonialCarousel {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 0px;
  }

  .testimonialCard {
    box-sizing: border-box;
    gap: 0px;
  }

  .testimonialText {
    margin: 0px 24px;
  }
}
