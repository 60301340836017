@mixin light-grey() {
  opacity: 0.5;
  font-size: 20px;
  text-transform: uppercase;
}

$blue-color: #0f62e6;

.main {
  max-width: 1000px;
  margin: 10px auto;
  padding: 60px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    padding-bottom: 0px;
  }
}

.header {
  text-align: center;
  margin-bottom: 20px;

  .preHeader {
    @include light-grey;
  }
}

.table {
  border-spacing: 0;
  text-align: left;
  margin: 0 auto;

  th {
    font-weight: 300;
    border-bottom: 1px solid #d8d8d8;

    > div {
      @include light-grey;
    }

    &:nth-child(2) {
      > div {
        font-weight: 600;
        color: $blue-color;
        opacity: 1;
      }
    }
  }

  th,
  td {
    padding: 20px;
    max-width: 300px;
  }

  td {
    vertical-align: top;
    font-weight: 600;

    &:nth-child(2) > div {
      color: $blue-color;
    }

    ul {
      padding: 0 0 0 10px;
      margin: 10px 0;
      font-weight: 300;
    }
  }

  /*
   * Mobile styles
   */

  .mobileTitle {
    display: none;
    font-weight: 300;
    border-top: 1px solid #d8d8d8;
    font-size: 24px;
  }

  @media (max-width: 600px) {
    thead {
      display: none;
    }

    .mobileTitle {
      display: table-cell;
    }

    .desktopTitle {
      display: none;
    }

    tbody td {
      width: 50%;
    }
  }
}

.mainB {
  max-width: 1000px;
  margin: 10px auto;
  padding: 60px 0;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    text-align: left;
    .desc {
      font-size: 18px;
      line-height: 1.4;
      max-width: 500px;
      width: 50%;
      margin-left: auto;

      p:first-of-type {
        margin-top: 0;
      }
    }
  }
  th {
    font-weight: 300;
    border-bottom: 1px solid #d8d8d8;

    &:nth-child(2),
    &:nth-child(3) {
      > div {
        color: #000;
        font-weight: 600;
        opacity: 1;
      }
    }
  }
  td {
    vertical-align: top;
    font-weight: 300;

    &:first-of-type {
      font-weight: 600;
    }

    &:nth-child(2) > div {
      color: #000;
    }

    ul {
      padding: 0 0 0 10px;
      margin: 10px 0;
      font-weight: 300;
    }
  }
  @media (max-width: 600px) {
    padding-top: 30px;
    .header {
      text-align: center;
      flex-direction: column;

      .desc {
        width: 85%;
        margin: 1rem auto;
      }
    }
  }
}
