.main {
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px 0 rgba(159, 170, 185, 0.1);
  border-radius: 4px;
  margin: 20px;
}

.petProfile {
  padding: 20px 20px 0 20px;
}
