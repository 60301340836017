@import '../../../styles/global/variables';

.container {
  font-size: 16px;
  font-family: $font-default;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
  background-color: #ffffff;
}

.headerContainer {
  padding: 5em;
  gap: 2em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header {
  gap: 2em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.title {
  font-family: $font-title;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;

  line-height: 100%;
  letter-spacing: -1.12px;

  color: #000000;
}

.subtitle {
  font-family: $font-default;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;

  line-height: 120%;
  letter-spacing: -0.48px;

  color: #000000;
}

.petCard {
  width: 100%;
  max-width: 600px;
  padding: 1em;
  border-radius: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--Primitives-Gray-gray-200, #bebebc);
  background: var(--Background-Light-Primary, #fff);
  box-sizing: border-box;
  gap: 1em;

  .petCardImage {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .petCardInfo {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
  }

  .petCardStatus {
    color: var(--Primitives-Green-green-100, #12cf75);

    /* Text/Caption Bold */
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;

    &.isTransfer {
      color: var(--Primitives-Blue-blue-200, #1360f4);
    }
  }

  .petCardName {
    color: var(--Primitives-Gray-fi-black, #000);

    /* Text/Body Large Bold */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
  }

  .petCardChipId {
    color: var(--Type-Light-Secondary, #737373);

    /* Text/Caption */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
}

.ctaContainer {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  width: 100%;
  max-width: 600px;

  .ctaButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    padding: 18px 33px;
    box-sizing: border-box;
    flex-direction: row;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 100px;
    border: 1px solid #000000;
    background-color: #ffffff;
    color: var(--Type-Light-Primary, #000);
    text-align: center;

    /* Headline/H4 */
    font-family: $font-default;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.16px;
  }
}

.hero {
  display: flex;
  height: 700px;
  padding: 80px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
    url(../../../assets/images/success-hero.png) lightgray 50% / cover no-repeat;

  .heroTitle,
  .heroSubtitle {
    font-family: $font-title;
    color: var(--Type-Dark-Primary, #fff);
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 80px */
    letter-spacing: -1.6px;
    text-transform: uppercase;
  }

  .heroTitle {
    max-width: 656px;
  }

  .heroSubtitle {
    max-width: 540px;
  }

  .heroCta {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .heroCtaShop {
    color: var(--Type-Dark-Primary, #fff);
    text-align: center;

    /* Headline/H2 */
    font-family: $font-default;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.48px;

    display: flex;
    padding: 24px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 100px;
    background: var(--Background-Dark-Primary, #000);
  }

  .heroMobileNotification {
    display: flex;
    width: 343px;
    padding: 12px 16px 12px 8px;
    align-items: flex-start;
    gap: 12px;
    align-self: flex-end;
    border-radius: 16px;
    background: var(--Background-Light-Primary, #fff);

    .heroMobileNotificationIcon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 7.579px;
      background: var(--Background-Color-FiYellow, #fffa01);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .heroMobileNotificationContent {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 0.125em;
    }

    .heroMobileNotificationTitle {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .heroMobileNotificationSubtitle {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.171px;
    }

    .heroMobileNotificationTime {
      color: #838383;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.171px;
    }
  }
}

.collar {
  display: flex;
  height: 800px;
  padding: 80px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: center / cover no-repeat url(../../../assets/images/success-collar.png);

  .review {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
  }

  .reviewStars {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }

  .reviewCopy {
    display: flex;
    flex-direction: column;
    gap: 0.75em;
  }

  .reviewTitle {
    color: var(--Type-Light-Primary, #000);
    text-align: center;

    /* Headline/H3 */
    font-family: $font-default;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 44px */
    letter-spacing: -1.76px;
  }

  .reviewSubtitle {
    color: var(--Type-Light-Primary, #000);
    text-align: center;

    /* Headline/H5 */
    font-family: $font-default;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.48px;
  }

  .collarCta {
    display: flex;
    padding: 24px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 100px;
    border: 2px solid var(--Primitives-Gray-fi-black, #000);
    color: var(--Primitives-Gray-fi-black, #000);
    text-align: center;

    /* Headline/H2 */
    font-family: $font-default;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.48px;
  }
}

.gps {
  display: flex;
  height: 700px;
  box-sizing: border-box;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;

  .gpsLeft {
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    width: 50%;
    align-self: stretch;
    background: var(--Background-Dark-Secondary, #242424);
    box-sizing: border-box;

    .gpsContent {
      display: flex;
      flex-direction: column;
      gap: 1.5em;
      align-items: center;
    }

    .gpsTitle {
      color: #fff;
      text-align: center;

      /* Headline/H2 */
      font-family: $font-title;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 56px */
      letter-spacing: -1.12px;
    }

    .gpsSubtitle {
      color: #fff;
      text-align: center;

      /* Headline/H5 */
      font-family: $font-default;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      letter-spacing: -0.48px;
      max-width: 338px;

      em {
        color: #fffa01;

        /* Headline/H5 */
        font-family: $font-default;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.48px;
      }
    }

    .gpsCta {
      display: flex;
      padding: 24px 48px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 100px;
      border: 2px solid var(--Primitives-Fi-Yellow-fi-yellow, #fffa01);
      color: var(--Type-Dark-Primary, #fff);
      text-align: center;

      /* Headline/H2 */
      font-family: $font-default;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      letter-spacing: -0.48px;
    }
  }

  .gpsRight {
    align-self: stretch;
    width: 50%;
    overflow: hidden;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.testimonalSection {
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 5rem;
  box-sizing: border-box;
  text-align: left;
  color: #000;
  font-family: $font-title;

  .testimonialContent {
    max-width: 70rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    flex-grow: 1;
  }

  .testimonialTitle {
    font-family: $font-title;
    font-weight: 600;
    font-size: 56px;
    align-self: stretch;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 100%;
    opacity: 0.9;
    mix-blend-mode: normal;
    z-index: 0;
    font-size: 3.5rem;
  }

  .testimonialSubtitle {
    align-self: stretch;
    position: relative;
    font-size: 1.125rem;
    line-height: 140%;
    z-index: 2;
    font-family: $font-default;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    p {
      margin: 0;
    }

    em {
      background-color: #fffa01;
      font-style: normal;
      font-weight: 600;
    }
  }

  .testimonialLeft {
    max-width: 26.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 2.5rem;
  }

  .testimonialVideo {
    max-width: 26.5rem;
    border-radius: 16px;
    height: 47.125rem;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4.375rem 6.75rem;
    box-sizing: border-box;
    // background-image: url('video.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
}

.escapeSection {
  width: 100%;
  position: relative;
  height: 43.75rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: #fff;
  font-family: $font-title;
  box-sizing: border-box;

  .escapeSectionLeft {
    align-self: stretch;
    width: 50%;
    overflow: hidden;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .escapeSectionRight {
    align-self: stretch;
    width: 50%;
    box-sizing: border-box;
    background-color: #242424;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5rem;
    gap: 4rem;
  }

  .escapeSectionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
  }

  .escapeTitle {
    font-family: $font-title;
    font-weight: 600;
    font-size: 3.5rem;
    max-width: 26.75rem;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .escapeSubtitle {
    max-width: 21.75rem;
    font-size: 1.5rem;
    letter-spacing: -0.02em;
    line-height: 120%;
    font-weight: 600;
    font-family: $font-default;
    align-items: center;

    em {
      color: #fffa01;
      font-style: normal;
    }
  }

  .escapeCta {
    border-radius: 100px;
    border: 2px solid #fffa01;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 3rem;
    font-size: 1.5rem;
    font-family: $font-default;
    letter-spacing: -0.02em;
    line-height: 120%;
    font-weight: 600;
    color: #ffffff;
  }
}

.progressBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f2;
  padding: 80px;

  .progressBar {
    display: flex;
    flex-direction: row;
    margin-bottom: 64px;
    width: 100%;
    position: relative;

    .progressBarElement {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% / 3);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 50%;
        height: 2px;
        background-color: black;
        margin-top: 22px;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 50%;
        height: 2px;
        background-color: black;
        margin-top: 22px;
        z-index: 1;
      }

      &:first-child::before {
        display: none;
      }

      &:last-child::after {
        display: none;
      }

      .progressBarText {
        color: black;
        text-align: center;
        font-family: $font-default;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.4px;
        margin-bottom: 20px;
        white-space: nowrap;
      }

      .progressBarIconYellow {
        display: flex;
        width: 38px;
        height: 38px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 100px;
        background: #fffa01;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
        z-index: 2;
      }

      .progressBarIconWhite {
        display: flex;
        width: 38px;
        height: 38px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 100px;
        background: white;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
        z-index: 2;
      }
    }
  }

  .progressBarHeaderText {
    max-width: 942px;
    font-family: $font-title;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 100%;
    letter-spacing: -1.6px;
    text-transform: uppercase;
    color: black;
  }

  .progressBarFiCollar {
    display: block;
    width: 100%;
    height: auto;
    max-width: 926px;
    margin-top: -5%;
    margin-bottom: -5%;
  }

  .progressBarCta {
    display: flex;
    padding: 24px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 100px;
    color: white;
    background-color: black;
    text-align: center;

    /* Headline/H2 */
    font-family: $font-default;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.48px;
  }

  .progressBarTestimonialContainer {
    background-color: white;
    border-radius: 24px;
    padding: 40px;
    margin-top: 64px;

    .review {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5em;
    }

    .reviewStars {
      display: flex;
      flex-direction: row;
      gap: 0.5em;
    }

    .reviewCopy {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .reviewTitle {
      color: var(--Type-Light-Primary, #000);
      text-align: center;

      /* Headline/H3 */
      font-family: $font-default;
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 44px */
      letter-spacing: -1.76px;
    }

    .reviewSubtitle {
      color: var(--Type-Light-Primary, #000);
      text-align: center;

      /* Headline/H5 */
      font-family: $font-default;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      letter-spacing: -0.48px;
    }
  }
}

@media (max-width: 600px) {
  .headerContainer {
    padding: 16px;
  }

  .titleContainer {
    gap: 0.5em;
  }

  .subtitle {
    font-size: 1rem;
  }

  .ctaContainer {
    gap: 16px;
    .ctaButton {
      padding: 16px 20px;
      font-size: 16px;
    }
  }

  .hero {
    padding: 1.5em;
    height: 600px;
    background-position: right center;

    .heroTitle,
    .heroSubtitle {
      font-size: 3.5em;
      width: 100%;
    }

    .heroCta {
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
    }

    .heroMobileNotification {
      align-self: flex-start;
    }
  }

  .collar {
    height: 550px;
    padding: 2.5em 1em;
    gap: 160px;
    background: calc(50% - 25px) calc(50% + 100px) / cover no-repeat
      url(../../../assets/images/success-collar-mobile.png);
    justify-content: flex-start;
    box-sizing: border-box;

    .review {
      gap: 1em;
    }

    .reviewCopy {
      gap: 0.5em;
    }

    .reviewTitle {
      font-size: 2em;
      font-family: $font-default;
      font-weight: 600;
      letter-spacing: -0.48px;
      line-height: 120%; /* 38.4px */
    }

    .reviewSubtitle {
      font-size: 1em;
      font-weight: 500;
    }

    .reviewStars > svg {
      width: 24px;
      height: 24px;
    }

    .collarCta {
      padding: 18px 33px;
      font-size: 16px;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
      letter-spacing: -0.16px;
    }
  }

  .gps {
    flex-direction: column;
    height: auto;

    .gpsLeft {
      width: 100%;
      gap: 2.5em;
      justify-content: flex-start;

      .gpsContent {
        max-width: 275px;
      }

      .gpsTitle {
        font-size: 2.5rem;
        font-weight: 700;
      }

      .gpsSubtitle {
        font-size: 1rem;
        font-weight: 600;

        em {
          font-size: 1rem;
        }
      }

      .gpsCta {
        padding: 18px 33px;
        font-size: 16px;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
        letter-spacing: -0.16px;
      }
    }

    .gpsRight {
      height: 400px;
      width: 100%;
    }
  }

  .testimonalSection {
    font-size: 16px;
    padding: 1.5em 1em;

    .testimonialContent {
      flex-direction: column;
      gap: 1.5em;
      width: 100%;
    }

    .testimonialLeft {
      width: 100%;
      gap: 1.5em;
    }

    .testimonialTitle {
      font-family: $font-title;
      font-size: 2.5em;
    }

    .testimonialSubtitle {
      font-size: 1em;
    }

    .testimonialVideo {
      width: 100%;
    }
  }

  .escapeSection {
    height: auto;
    flex-direction: column;
    width: 100%;

    .escapeSectionLeft {
      width: 100%;
      height: 400px;
      order: 1;
    }

    .escapeSectionRight {
      width: 100%;
      gap: 2.5em;
      order: 0;
    }

    .escapeTitle {
      font-size: 2.5em;
    }

    .escapeSubtitle {
      font-size: 1em;
      letter-spacing: -0.16px;
    }

    .escapeCta {
      padding: 18px 33px;
      font-size: 16px;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
      letter-spacing: -0.16px;
    }
  }

  .progressBarContainer {
    padding: 24px;

    .progressBar {
      margin-bottom: 40px;
      .progressBarElement {
        .progressBarText {
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
          margin-bottom: 12px;
        }

        &::before,
        &::after {
          margin-top: 18px;
        }
      }
    }

    .progressBarHeaderText {
      font-size: 56px;
      letter-spacing: -1.12px;
    }

    .progressBarCta {
      padding: 18px 32px;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.16px;
    }

    .progressBarTestimonialContainer {
      border-radius: 24px;
      padding: 24px;
      margin-top: 40px;

      .review {
        gap: 1em;
      }

      .reviewCopy {
        gap: 0.5em;
      }

      .reviewTitle {
        font-size: 20px;
        font-family: $font-default;
        font-weight: 600;
        letter-spacing: -0.48px;
        line-height: 120%; /* 38.4px */
      }

      .reviewSubtitle {
        font-size: 14px;
        font-weight: 500;
      }

      .reviewStars > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
