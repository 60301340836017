@import '../../styles/global/variables';

.main {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  padding: 0.54em 0.9em;
  border-radius: 0.18em;
  font-size: 1.375rem;
  line-height: 1.28em;
  letter-spacing: 0.025em;
  background-color: #f8f8f8;
  color: #000000;
}

.primary {
  background-color: #000000;
  color: #ffffff;

  &[disabled] {
    background-color: #c2c2c2;
    color: #ffffff;
  }
}

.icon {
  margin: 0 0 0 0.64em;
}
