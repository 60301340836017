@import '../../styles/global/variables';

.main {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  text-transform: uppercase;
  font-size: $av-font-size-sm;
  font-weight: 500;
  padding: 10px 20px;
}
