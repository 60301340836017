// @import "../../../styles/global/mixins";
@import '../../../../styles/global/mixins';

.main {
  width: 100%;

  :global .form-field {
    justify-content: center;
    flex-basis: 50%;
  }

  .breedModifiers {
    :global .form-field {
      min-height: 34px;

      @include tablet-and-desktop-only {
        min-height: 54px;
      }
    }
  }
}

.unknownBreed {
  padding: 0.5em;
  line-height: 1.25em;
  font-size: 1.5rem;
}

.isNotADog {
  visibility: hidden;
}
