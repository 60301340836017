@import '../../styles/global/variables';
@import '../../styles/global/mixins';

.main {
  display: flex;
  background-color: $fi-yellow-color;
  align-items: center;
  background-image: url('../../assets/images/fi-nano-bg-web.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

  @include mobile-and-tablet-only {
    background-image: url('../../assets/images/fi-nano-bg-mobile.png');
    background-position: bottom center;
    text-align: center;
    background-size: cover;
  }

  .contents {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    margin: 100px 0px 100px 50px;

    @include desktop-only {
      max-width: 600px;
    }

    @include mobile-and-tablet-only {
      margin: 50px 20px 280px 20px;
    }
  }
}
