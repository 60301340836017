@import '../../../styles/global/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
}

.mainContent {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 656px;
  padding: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 32px;

  border-radius: 24px;
  background-color: #ffffff;
}

.progressText {
  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.14px;
  color: #1360f4;
}

.progressFillBoxes {
  display: flex;
  height: 3px;
  gap: 6px;
  width: 100%;
  margin-top: 4px;
}

.progressFillBox1,
.progressFillBox2,
.progressFillBox3,
.progressFillBox4 {
  background-color: #1360f4;
  width: 100%;
}

.progressFillBox5 {
  background-color: #e5e5e4;
  width: 100%;
}

.resellerContainer .progressFillBox5 {
  display: none;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.title {
  font-family: $font-title;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;

  line-height: 100%;
  letter-spacing: -1.12px;

  color: #000000;
}

.subtitle {
  font-family: $font-default;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;

  line-height: 120%;
  letter-spacing: -0.48px;

  color: #000000;
}

@media (max-width: 600px) {
  .mainContent {
    max-width: none;
    width: 100%;
    border-radius: 0px;
    padding: 24px 16px;
    gap: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .title {
    font-size: 40px;
  }

  .subtitle {
    font-size: 16px;
  }
}
