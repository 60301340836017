@import '../../../styles/global/mixins';
@import '../../../styles/global/variables';

$navbar-breakpoint: 1250px;

.navbar {
  width: 100%;
  max-width: 1328px;
  font-family: $font-default;
  font-size: 16px;
  height: 5.5rem;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #f3f3f2;

  .navbarLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: content-box;
  }

  .navbarBrand {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;

    a {
      display: inline-block;
    }

    svg {
      height: 1.5em;
      display: block;
    }
  }

  .navbarToggler {
    border: none;
    padding: 0;
    align-items: center;
    display: none;
  }

  .navbarNav {
    display: flex;
    flex-direction: row;
    gap: 2.5em;

    a {
      text-decoration: none;
      color: #000000;
      font-weight: 600;
      font-size: 18px;
    }
  }

  .navbarAction {
    display: flex;

    .navbarBtn {
      display: flex;
      padding: 10px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 100px;
      background: #000000;
      color: #ffffff;
      font-family: $font-default;
      font-size: 14px;
      font-weight: 600;
      line-height: 120%; /* 16.8px */
      letter-spacing: -0.14px;
    }
  }
}

.navbarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  top: 0;
  left: 0;
  position: sticky;
  position: -webkit-sticky;
  z-index: 9999;
}

.navLink {
  color: #000000;
}

@media (max-width: $navbar-breakpoint) {
  .navbarContainer {
    justify-content: flex-start;
    align-items: stretch;

    &.expanded {
      position: fixed;
      width: 100%;
      height: 100vh;
      z-index: 1000;

      .navbarNav {
        display: flex;
      }

      .navbarAction {
        display: flex;
      }
    }
  }

  .navbar {
    height: 5em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 2em;

    .navbarLeft {
      width: 100%;
      height: 3em;
    }

    .navbarBrand {
      padding: 0.85em;

      svg {
        height: 1.25em;
      }
    }

    .navbarToggler {
      display: flex;
    }

    .navbarNav {
      background-color: #ffffff;
      display: none;
      flex-direction: column;
      gap: 2em;
    }

    .navbarAction {
      display: none;

      .navbarBtn {
        font-size: 18px;
        color: #000000;
        background: none;
        padding: 0;
      }
    }
  }
}
