@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

.main {
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  border: 1px solid #e4e4e4;
  box-shadow: 0 0 6px 0 rgba(159, 170, 185, 0.2);
  border-radius: 4.8px;
  padding: 1rem;
  background: white;
  align-items: center;

  input[type='text'] {
    margin-right: 10px;
    font-size: 26px;
    border: none;
    padding: 0;
    flex-grow: 1;

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
}

.searchButton {
  background: #000000;
  color: white;
  border-radius: 2px;
  padding: 10px 30px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 0.5px;

  @media (max-width: 600px) {
    padding: 10px 15px;
  }
}
