@import '../../../styles/global/variables';

.breedSelectModal {
  display: flex;
  flex-direction: column;
}

.button {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  background-color: white;
  color: black;
  height: 56px;
  padding: 16px 24px;
  border: 1px solid #bebebb;
  border-radius: 100px;
  text-align: left;
  width: 100%;
}

.buttonPlaceholder {
  color: #9e9e9c;
}

.modalContainer {
  padding: 20px;
  background: white;
  max-width: 95%;
}

.modalList {
  max-height: 50vh;
  overflow-y: scroll;
  overscroll-behavior: contain;
}

.modalHeader {
  color: black;
  text-align: center;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.16px;
  margin-bottom: 17px;
}

.modalCloseButton {
  position: absolute;
  top: 22px;
  right: 16px;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.searchBar {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 86px;
  border: 1px solid #bebebb;
  background: white;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.modalListItem {
  display: flex;
  padding: 16px 0px 17px 0px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;
  cursor: pointer;
}
