@import '../RegisterChip/styles/variables';
@import '../../styles/global/mixins';

.container {
  padding: 0;
  margin: 0;
  background-color: #f3f3f2;
  max-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 90px);
}

@media (max-width: 600px) {
  .container {
    background-color: white;
  }
}
