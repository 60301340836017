.main {
  list-style-type: none;
  padding: 0;
  margin: 0;

  &.horizontal {
    display: flex;
  }

  &.relaxed {
    &:not(.horizontal) {
      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.horizontal {
      li {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
