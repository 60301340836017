@import '../../../styles/global/mixins';
@import '../../../styles/global/variables';

.main {
  padding: 0px;
  background-color: $fi-yellow-color;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url('../../../assets/images/final-hero/desktop-squig-left.png'),
    url('../../../assets/images/final-hero/desktop-squig-right.png');
  text-align: center;
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: bottom left, bottom right;

  @include mobile-and-tablet-only {
    background-image: url('../../../assets/images/final-hero/mobile-squig.png');
    background-size: contain;
    background-position: bottom center;
  }

  h3 {
    margin: 0 0 30px 0;
  }

  :global(.btn-black) {
    font-size: 18px;
  }

  .left,
  .right {
    width: 50%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .left {
    background: #000 url('../../../assets/images/final-hero/desktop-squig-left2.png') center no-repeat;
    color: #fff;

    h5 {
      color: #fff;
      opacity: 0.55;
    }
  }

  .right {
    background: #ffff01 url('../../../assets/images/final-hero/desktop-squig-right2.png') center no-repeat;
  }
  @include mobile-and-tablet-only {
    flex-direction: column;
    .left,
    .right {
      width: 100%;
    }
  }
}
