@import '../../styles/global/mixins';

.actions {
  text-align: center;
  padding: 1em 0px;
}

.signupOptions {
  margin-top: 20px;
}

.signupPrompt {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.55);
  margin-bottom: 10px;
}
