$font-family-sans-serif: 'DIN2014-Narrow', 'Impact', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

$font-family-big-font: 'Pakt', $font-family-sans-serif;

$font-default: 'MessinaSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif;

$font-title: 'MessinaSans-Compact', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif;

$fi-yellow-color: #ffff01;
$fi-gray-color: rgba(0, 0, 0, 0.55);

$status-review-color: #027eff;
$status-active-color: #2cb355;
$status-shipped-color: rgba(0, 0, 0, 0.4);

$av-font-size-lg: 32px;
$av-font-size-md: 26px;
$av-font-size-sm: 21px;
$av-font-size-xsm: 18px;

$xs-max-width: 480px;
$sm-min-width: 481px;
$sm-max-width: 768px;
$md-min-width: 769px;
$md-max-width: 1024px;
$lg-min-width: 1025px;
$lg-max-width: 1280px;
$xl-min-width: 1281px;
$xl-max-width: 1440px;
$xxl-min-width: 1441px;
