@mixin mobile-only {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: 600px) and (max-width: 980px) {
    @content;
  }
}

@mixin mobile-and-tablet-only {
  @media (max-width: 980px) {
    @content;
  }
}

@mixin tablet-and-desktop-only {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: 980px) {
    @content;
  }
}
