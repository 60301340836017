@import './variables';
@import './mixins';

h1 {
  text-transform: uppercase;
  font-family: $font-family-big-font;
  font-size: 60px;
  line-height: 65px;

  @include tablet-only {
    line-height: 50px;
    font-size: 45px;
  }

  @include mobile-only {
    line-height: 35px;
    font-size: 30px;
  }
}

h3 {
  font-size: 34px;
  font-weight: 600;
  margin: 0;
  line-height: 0.96;
}

h4 {
  font-size: 30px;
  font-weight: 600;
  margin: 30px 0;
}

h5 {
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
  color: $fi-gray-color;
  letter-spacing: 0.025rem;
  margin-bottom: 0.5rem;
}

.lowercase {
  text-transform: lowercase;
}

.font-lg {
  font-size: 28px;
}

.font-md {
  font-size: 24px;
}

.font-sm {
  font-size: 16px;
}

.font-xsm {
  font-size: 14px;
}

@include mobile-and-tablet-only {
  .font-lg {
    font-size: 1.25rem;
  }

  .font-md {
    font-size: 1rem;
  }
}
