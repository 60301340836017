@import '../../../styles/global/mixins';

.main {
  padding: 60px 80px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url('../../../assets/images/scribble-bg-web.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

  h3 {
    margin: 0;
  }

  @include mobile-and-tablet-only {
    background-image: url('../../../assets/images/scribble-bg-mobile.png');
    background-position: bottom center;
    flex-direction: column;
    text-align: center;
    padding: 20px 0px;

    > :first-child {
      margin-bottom: 30px;
    }
    h3 {
      width: 70%;
      margin: 0 auto 1rem;
    }
  }
}

.mainB {
  padding: 30px 80px;
  background-color: #ffff01;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url('../../../assets/images/scribble-bg-web2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

  @include mobile-and-tablet-only {
    background-image: url('../../../assets/images/scribble-bg-mobile2.png');
    background-position: bottom center;
    flex-direction: column;
    text-align: center;

    > :first-child {
      margin-bottom: 50px;
    }
  }

  h3 {
    margin: 0;
  }
}
