@import '../../../styles/global/mixins';

.main {
  display: flex;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include mobile-and-tablet-only {
    flex-direction: column;
    h5 {
      margin-top: 2rem;
    }
    img {
      width: 100%;
    }
    .upsellImage {
      width: 100%;
    }
  }
}

.left {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;

  > * {
    display: Flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    height: 50%;
    flex-grow: 1;

    > * {
      max-width: 400px;
      width: 100%;
    }

    @include mobile-and-tablet-only {
      padding: 0 20px;

      ul {
        box-sizing: border-box;
        padding-left: 20px;
      }
    }

    @include desktop-only {
      width: 100%;
    }
  }

  p {
    margin: 10px 0 0 0;
    font-size: 20px;
    line-height: 1.3;
  }
}

.upsellImage {
  width: 50%;
  overflow: hidden;
}

.theProblem {
  margin-bottom: 10px;
}
