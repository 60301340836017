.main {
  background: #ffffff;
  border: 0 solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 8px 0 rgba(159, 170, 185, 0.1);
  border-radius: 3.2px;
  display: flex;
  padding: 20px;
  font-size: 18px;
}

.pictureContainer {
  margin-right: 20px;

  img {
    $size: 75px;

    object-fit: cover;
    width: $size;
    height: $size;
    border-radius: 50%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
