@import '../../../styles/global/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
}

.mainContent {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 656px;
  padding: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 32px;

  border-radius: 24px;
  background-color: #ffffff;
}

.progressText {
  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.14px;
  color: #1360f4;
}

.progressFillBoxes {
  display: flex;
  height: 3px;
  gap: 6px;
  width: 100%;
  margin-top: 4px;
}

.progressFillBox1,
.progressFillBox2,
.progressFillBox3 {
  background-color: #1360f4;
  width: 100%;
}

.progressFillBox4,
.progressFillBox5 {
  background-color: #e5e5e4;
  width: 100%;
}

.resellerContainer .progressFillBox5 {
  display: none;
}

.title {
  font-family: $font-title;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;

  line-height: 100%;
  letter-spacing: -1.12px;

  color: #000000;
}

.photoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .photoContainer {
    position: relative;
  }

  .imageContainer {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .addIcon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background-color: #000000;
    border-radius: 50%;
    position: absolute;
    z-index: 100;
    right: -8px;
    bottom: 0px;
  }
}

:global(.form-section-header-container) {
  display: flex;
  justify-content: space-between;
}

:global(.form-section-header-container) :global(.button-main) {
  padding: 0px;
  height: initial;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.container {
  textarea {
    border-radius: 24px;
    border: 1px solid #bebebb;
    background: white;
    padding: 16px 24px 0px 24px;

    align-self: stretch;
    font-family: $font-default;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
}

@media (max-width: 530px) {
  .container {
    textarea {
      padding: 16px 24px 16px 24px;
    }
  }
}

@media (max-width: 600px) {
  .mainContent {
    max-width: none;
    width: 100%;
    border-radius: 0px;
    padding: 24px 16px;
    gap: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .title {
    font-size: 40px;
  }
}
