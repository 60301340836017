@import '../../../styles/global/mixins';

.main {
  margin-bottom: 40px;

  @include mobile-and-tablet-only {
    order: 100;
  }

  input[type='submit'] {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    font-size: 24px;
    font-weight: 300;
  }

  input[type='text'] {
    border-bottom: none;
    flex-grow: 1;
  }

  form {
    border-bottom: 1px solid #d8d8d8;
    display: flex;
  }
}

.thankYou {
  padding: 10px;
  color: rgba(0, 0, 0, 0.5);
}
