@import '../../../../styles/global/variables';

.main {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d8d8d8;
  margin: 20px 0;
  padding: 20px 20px 0 20px;
}

.title {
  font-size: $av-font-size-sm;
  opacity: 0.6;
  text-transform: uppercase;
}
