@import '../../../styles/global/variables';

.main {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px 0 rgba(159, 170, 185, 0.1);
  border-radius: 4px;
  margin: 0 20px 20px 20px;
  cursor: pointer;

  .status {
    color: $status-review-color;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      background-color: $status-review-color;
      margin: 0 10px 4px 0;
    }
  }

  .primarySerialNumber {
    font-size: $av-font-size-lg;
    font-weight: 500;
  }

  .secondarySerialNumber {
    font-size: $av-font-size-sm;
  }
}
