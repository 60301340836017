@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

.main {
  display: flex;
  background-color: $fi-yellow-color;
  align-items: center;
  background-image: url('../../../assets/images/search-hero/desktop-right-nano.png'),
    url('../../../assets/images/search-hero/desktop-left-nano.png');
  background-repeat: no-repeat;
  background-size: 310px, 400px;
  background-position: bottom right, calc(100% - 270px) 0;

  @media (max-width: 1300px) {
    background-image: url('../../../assets/images/search-hero/desktop-right-nano.png');
    background-position: bottom right;
  }

  @media (max-width: 980px) {
    background-image: none;
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 600px) {
    background-image: url('../../../assets/images/search-hero/mobile-left-nano.png'),
      url('../../../assets/images/search-hero/mobile-right-nano.png');
    background-position: bottom left, bottom right;
    background-size: 240px, 140px;
  }

  .contents {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    margin: 100px 0px 100px 50px;

    @include desktop-only {
      max-width: 600px;
    }

    @include mobile-and-tablet-only {
      margin: 50px 20px 280px 20px;
    }

    @media (max-width: 980px) and (min-width: 600px) {
      margin-bottom: 150px;
    }
  }
}
