@import '../../styles/global/variables';

.main {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 10px 30px 10px;
}

.tab {
  opacity: 0.55;
  font-size: $av-font-size-md;
  text-align: center;
  cursor: pointer;
}

.selected {
  font-weight: 600;
  opacity: 1;
  position: relative;

  &::after {
    $width: 16px;

    content: '';
    position: absolute;
    height: 4px;
    width: $width;
    border-radius: 2.5px;
    bottom: -10px;
    left: calc(50% - calc($width / 2));
  }

  &.active::after {
    background-color: $status-active-color;
  }

  &.review::after {
    background-color: $status-review-color;
  }

  &.shipped::after {
    background-color: $status-shipped-color;
  }
}
