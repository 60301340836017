@import '../../../styles/global/variables';

:global(.register-chip-next) .contactInfoForm {
  & > form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    label {
      font-size: 16px;
      font-family: $font-default;
      font-weight: 600;
    }
  }
}

@media (max-width: 600px) {
  :global(.register-chip-next) .contactInfoForm {
    & > form {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}
